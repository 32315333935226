<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="日期" :labelCol='labelcol' :wrapperCol="wrappercol">
            <a-date-picker v-model="form.search_date" @change="onChange" />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="时间区间" :labelCol='labelcol' :wrapperCol="wrappercol" prop="time_section">
            <a-select v-model="form.time_section" placeholder="请选择时间区间...">
              <a-select-option value="1">
                1小时
              </a-select-option>
              <a-select-option value="2">
                2小时
              </a-select-option>
              <a-select-option value="3">
                3小时
              </a-select-option>
              <a-select-option value="4">
                4小时
              </a-select-option>
              <a-select-option value="6">
                6小时
              </a-select-option>
              <a-select-option value="8">
                8小时
              </a-select-option>
              <a-select-option value="12">
                12小时
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>


        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>
    <!-- <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAdd">
        添加用户
      </a-button>
    </div> -->
    <div class="mainContent" style="overflow: auto;">
      <!-- <div class="tableHeader">
        <div class="title">查询表格</div>
      </div> -->
      <div id="myChart" style="min-width:360px;"></div>
    </div>


    <!-- 饼图 -->
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleFormPie" :model="formPie">


        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="统计类型" :labelCol='labelcol' :wrapperCol="wrappercol" prop="type">
            <a-select v-model="formPie.type" placeholder="请选择统计类型" >
              <a-select-option value="country">
                国家统计
              </a-select-option>
              <a-select-option value="payment">
                支付方式统计
              </a-select-option>
              <a-select-option value="country_lv">
                国家付款率统计
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <!-- <a-col :sm="24" :md="12" :lg="8" :xl="8">
          <a-form-model-item label="时间" :labelCol='labelcol' :wrapperCol="wrappercol">
            <a-range-picker
              :default-value="[moment(new Date(), 'YYYY-MM-DD HH:mm:ss').startOf('day'), moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day')]"
              :show-time="{ format: 'HH:mm:ss' }" format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '结束时间']"
              @change="onChangePie" @ok="onOk" />
          </a-form-model-item>
        </a-col> -->

        <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
          <a-form-model-item label="开始日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
            <a-date-picker
            v-model="formPie.start_time"
            :show-time="{defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" format="YYYY-MM-DD HH:mm:ss" @change="onChangePieStart" @ok="onOk"/>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
          <a-form-model-item label="结束日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
            <a-date-picker
            v-model="formPie.end_time"
            format="YYYY-MM-DD HH:mm:ss"
            :show-time="{ defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" @change="onChangePieEnd"/>
          </a-form-model-item>
        </a-col>


        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="searchPie">
              搜索
            </a-button>
            <a-button type="primary" @click="exportPie" :loading='isExport'>
              导出
            </a-button>
            <a-button @click="reset('ruleFormPie')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" style="overflow: auto;">

      <div id="myChartPie" style="min-width:360px;"></div>
    </div>

  </div>
</template>
<script>
  import { getStatisticsByPolygonalLine, getStatisticsByCircle,orderStatisticExport } from "@/axios/orderEcharts.js"
  import {myMixin} from "@/static/js/mixin.js"
  import * as echarts from 'echarts'
  import moment from 'moment';

  window.onresize = function () {
    myChart.resize();
    myChartPie.resize();
  };

  let myChart = null

  let totalChart = [//配置2种图表的数据
    {
      name: '订单总数',
      type: 'line',
      encode: {
        x: 0, y: 1
      },
      xAxisIndex: 0,
      yAxisIndex: 0,
    },
    {
      name: '订单成功数',
      type: 'line',
      encode: {
        x: 0, y: 2
      },
      xAxisIndex: 0,
      yAxisIndex: 0,
    },
  ]
  let rateChart = [
    {
      name: '订单成功率',
      type: 'line',
      encode: {
        x: 0, y: 3
      },
      xAxisIndex: 0,
      yAxisIndex: 0,
    },

  ]
  //配置饼图数据
  let myChartPie = null
  export default {
    mixins: [myMixin],
    data() {
      return {       
        form: {
          search_date: "",
          time_section: '1',
        },
        formPie: {
          type: "country",
          start_time: '1',
          end_time: '',
        },
        data: [],//数据
        dataPie: [],//数据

        isExport:false,
      }

    },
    created() {
      this.form.search_date = this.moment(new Date()).format('YYYY-MM-DD')
      this.formPie.start_time = this.moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.formPie.end_time = this.moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      let that = this

    },
    mounted() {
      setTimeout(() => {
        this.createChart()
        this.createChartPie()
        this.fetch();
        this.fetchPie();
      }, 0)

    },
    methods: {
      moment,
      reset(formName) {
        this.$refs[formName].resetFields();
        this.fetch()
      },
      search() {//搜索
        this.fetch();
      },

      onChange(date, dateString) {//时间 
        this.form.search_date = dateString;
      },

      searchPie() {//搜索饼图
        this.fetchPie();
      },
      onChangePieStart(date, dateString) {//时间 
        this.formPie.start_time = dateString;
      },
      onChangePieEnd(date, dateString) {//时间 
        this.formPie.end_time = dateString;
      },
      onOk(value) {
        console.log('onOk: ', value);
      },

      toTotal() {
        //切换图表，先复制当前实例数据，再吧当前实例中的图表信息替换掉，然后清除当前实例，再用修改完的实例数据重新创建
        let a = myChart.getOption();
        a.series=[...totalChart]
        myChart.clear()
        myChart.setOption({
          ...a,
        })
      },
      toRate() {
        let a = myChart.getOption();
        a.series.splice(0, 2)
        a.series.unshift(...rateChart)
        myChart.clear()
        myChart.setOption({
          ...a,
        })
      },
      createChart() {
        let that = this
        // 基于准备好的dom，初始化echarts实例
        myChart = echarts.init(document.getElementById('myChart'));
        // 绘制图表
        myChart.setOption({
          title: {
            text: '按时区统计'
          },
          media: [ // 这里定义了 media query 的逐条规则。设置移动端规则
            {
              query: {
                maxAspectRatio: 1,//长宽比，长度对于宽度的比例
              }, // 这里写规则。
              option: { // 这里写此规则满足下的option。
                legend: { top: "10%" },
                grid: [
                  { top: "20%", left: '10%', width: "85%", height: "70%", },
                ],
              }
            },
            { // 这条里没有写规则，表示『默认』，
              option: { // 即所有规则都不满足时，采纳这个option。
              }
            }
          ],

          toolbox: {//工具栏配置
            show: true,
            feature: {
              myTotal: {//自定义两个工具事件
                show: true,
                title: '查看订单总数',
                icon: 'path://M873.48 933.77c-16.22 0-29.36-14.33-29.36-32v-754c0-11.05-8.22-20-18.35-20H230.75c-16.22 0-29.36-14.33-29.36-32s13.15-32 29.36-32h598.69c40.54 0 73.4 35.82 73.4 80v758c0.01 17.67-13.14 32-29.36 32z M713.71 224.22c8.82 0 16 7.18 16 16v640c0 8.82-7.18 16-16 16h-534c-8.82 0-16-7.18-16-16v-640c0-8.82 7.18-16 16-16h534m0-64h-534c-44.18 0-80 35.82-80 80v640c0 44.18 35.82 80 80 80h534c44.18 0 80-35.82 80-80v-640c0-44.18-35.82-80-80-80z M648.9 397.33H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32H648.9c17.67 0 32-14.33 32-32s-14.32-32-32-32zM564.02 549.69H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32h322.01c17.67 0 32-14.33 32-32s-14.32-32-32-32z',
                onclick: function () {
                  that.toTotal()
                }
              },
              myRatio: {
                show: true,
                title: '查看订单成功率',
                icon: 'path://M760.974293 800.695048H264.491031a10.466602 10.466602 0 0 1-10.466602-10.466602V229.898911a10.466602 10.466602 0 0 1 10.466602-10.466601h134.23417v20.933203H274.957633V779.761844h475.550059V240.365513H627.996116v-20.933203h133.135176a10.466602 10.466602 0 0 1 10.466602 10.466601V790.228446a10.466602 10.466602 0 0 1-10.623601 10.466602z M655.994276 277.364951H371.564369l37.418101-83.732815h209.646037z m-252.14044-20.933204h219.79864l-18.630551-41.866407H422.53672zM344.822201 627.996116a10.466602 10.466602 0 0 1-7.169623-2.878316 10.466602 10.466602 0 0 1-0.418664-14.810241L423.897378 518.463127a10.466602 10.466602 0 0 1 7.535954-3.244647 10.466602 10.466602 0 0 1 7.535953 3.192314l80.121838 83.104819 137.217151-159.877344a10.466602 10.466602 0 1 1 15.699903 13.606582l-144.648439 168.616957a10.466602 10.466602 0 0 1-7.640619 3.663311 10.466602 10.466602 0 0 1-7.849952-3.192314l-80.540501-83.732815-79.28451 83.732815a10.466602 10.466602 0 0 1-7.221955 3.663311z M679.962795 537.774007a10.466602 10.466602 0 0 1-10.466602-10.466602V444.830582h-82.58149a10.466602 10.466602 0 0 1 0-20.933204h93.048092a10.466602 10.466602 0 0 1 10.466601 10.466602v92.995758a10.466602 10.466602 0 0 1-10.466601 10.414269z',
                onclick: function () {
                  that.toRate();
                }

              },
              magicType: {
                type: ["line", "bar"]
              },
              // restore: {}, //还原，跟缩放，禁用
              // dataZoom: {},
              saveAsImage: {},

            },
          },

          xAxis: [
            { type: 'category', gridIndex: 0 },
          ],


          yAxis: [
            { type: 'value', gridIndex: 0 },
          ],


          grid: [
            { top: "10%", left: '5%', width: "90%", height: "80%", },
          ],
          dataset: [//数据集，可以被多个组件复用
            { source: [] },
          ],

          legend: {
          },
          tooltip: {//提示工具
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
          },
          series: [//系列，代表每个数据表

            ...totalChart,
          ]
        });

      },

      createChartPie() {
        let that = this
        // 基于准备好的dom，初始化echarts实例
        myChartPie = echarts.init(document.getElementById('myChartPie'));
        // 绘制图表
        myChartPie.setOption({
          title: {
            text: '按国家/付款方式/国家付款率统计'
          },
          media: [ // 这里定义了 media query 的逐条规则。设置移动端规则
            {
              query: {
                maxAspectRatio: 1,//长宽比，长度对于宽度的比例
              }, // 这里写规则。
              option: { // 这里写此规则满足下的option。
                legend: {},
                series: [
                  { name: '国家', left: 'center', top: '15%', width: "100%", radius: [4, 90], },
                ]
              }
            },
            { // 这条里没有写规则，表示『默认』，
              option: { // 即所有规则都不满足时，采纳这个option。
                
              }
            }

          ],


          dataset: [//数据集，可以被多个组件复用
            { source: [] },
          ],
          legend: {
            type:"scroll",
            top: "30"
          },
          tooltip: {//提示工具
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
          },
          series: [//系列，代表每个数据表
            {
              label: {
                formatter: '{b}: {@[1]} ({d}%)'
              },
              name: '国家',

              type: 'pie',
              itemStyle: {
                borderRadius: 4
              },
              top:"13%",
              //roseType: 'area',//南丁格尔图。
              radius: [4, 140],
              encode: {
                itemName: 0, value: 1
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              tooltip: {//提示工具
                trigger: 'item',
                formatter: (param) => {
                  console.log(param)
                  return `<h3>${param.seriesName}</h3><span style="margin-right:10px;display:inline-block">${param.name}:</span>${param.data[1]}(${param.percent}%)`
                }
              },
              datasetIndex: 1,
            }
          ]
        });

      },
      async fetch(params = {}) {
        myChart.showLoading();
        let data = await getStatisticsByPolygonalLine({ ...this.form })
        this.data = data.data;
        myChart.hideLoading();
        let sourceData = [];//处理数据
        for (let item in this.data) {
          let i = this.data[item]
          let rate = 0;
          if (i.total != 0 && i.success_num != 0) {//0除以0会返回nan，所以判断，没有0除以0时才赋值
            rate = (i.success_num / i.total * 100).toFixed(2)
          }
          sourceData.push([item, i.total, i.success_num, rate])
        }
        console.log(sourceData, "折线图数据")
        //指定数据
        myChart.setOption({
          dataset: [
            {
              dimensions: ['时间区间', '订单总数', '订单成功数', '订单成功率',],//数据的维度
              source: sourceData
            },

          ],
        })
        
      },

      async fetchPie(params = {}) {
        myChartPie.showLoading();
        let data = await getStatisticsByCircle({ ...this.formPie })
        this.dataPie = data.data;
        myChartPie.hideLoading();
        let sourceData = [];//处理数据
        for (let item in this.dataPie) {
          let i = this.dataPie[item]
          // let rate=0;
          // if(i.total,i.success_total!=0&&i.success_total!=0){//0除以0会返回nan，所以判断，没有0除以0时才赋值
          //   rate=(i.success_total/i.total*100).toFixed(2)
          // }
          if (this.formPie.type == 'payment') {
            sourceData.push([i.payment, i.order_total])
          } else if(this.formPie.type == 'country_lv'){
            sourceData.push([i.country, i.pay_radio])
          }else {
            sourceData.push([i.country, i.order_total])
            // sourceData=[//测试数据
            //   ["AT", 1],
            //   ["AT2", 1],
            //   ["AT3", 1],
            //   ["AT4", 1],
            //   ["AT5", 12],
            //   ["AT6", 5],
            //   ["AT7", 1],
            //   ["AT8", 3],
            //   ["AT9", 1],
            //   ["AT0", 1],
            //   ["AT11", 1],
            //   ["AT12", 1],
            //   ["AT13", 1],
            //   ["AT14", 2],
            //   ["AT15", 1],
            //   ["AT16", 1],
            //   ["AT17", 1],
            //   ["AT18", 1],
            //   ["AT19", 1],
            //   ["AT20", 1],

            // ]
          }
        }
        let name='';
        let dimensions=[];
        //指定数据
        if(this.formPie.type == 'payment'){
          name='支付方式'
          dimensions=['支付方式','订单总数']
        }else if(this.formPie.type == 'country_lv'){
          name='国家付款率'
          dimensions=['国家付款率','付款率']
        }else{
          name='国家'
          dimensions=['国家','订单总数']
        }
        myChartPie.setOption({
          series:{ 
            name: name,
            roseType:this.formPie.type == 'payment' ? '' : 'area'
          },

          dataset: [
            {
              dimensions:dimensions,//数据的维度
              source: sourceData,

            },
            {
              transform: {
                type: 'filter',
                config: { dimension: this.formPie.type == 'country_lv'?'付款率':'订单总数', '>': 0 }
                // 这个筛选条件表示，遍历数据，筛选出维度（ dimension ）
                // 'Year' 上值为 2011 的所有数据项。
              }
            }

          ],
          // tooltip: {//提示工具
          //   trigger: 'item',
          //   formatter: (param) => {
          //     console.log(param)
          //     if(this.formPie.type == 'country_lv'){
          //       //做到这，提示信息国家付款率的话多几个字段，
          //       return `<h3>${param.seriesName}</h3><span style="margin-right:10px;display:inline-block">${param.name}:</span>${param.data[1]}(${param.percent}%)`//做到这，提示信息国家付款率的话多几个字段，
          //     }
          //     return `<h3>${param.seriesName}</h3><span style="margin-right:10px;display:inline-block">${param.name}:</span>${param.data[1]}(${param.percent}%)`
          //   }
          // },
        })
        console.log('饼图数据', sourceData)
        
      },

      async exportPie(){
        this.isExport=true
        let data=await orderStatisticExport({ ...this.formPie })
        this.isExport=false
        window.location.href = data.data;

      }
    }
  };
  
</script>
<style lang="scss" scoped>
  @import "@/static/css/index.scss";

  #myChart {
    width: 100%;
    height: 400px;
  }

  #myChartPie {
    width: 100%;
    height: 400px;
  }

  @media only screen and (max-width: 520px) {
    #myChart {
      width: 100%;
      height: 370px;
    }

    #myChartPie {
      width: 100%;
      height: 370px;
    }
  }
</style>