
import {get,getBlob,post} from "@/axios";

//订单图表
export function getStatisticsByPolygonalLine(data) {
    return get('/api/transactions/getStatisticsByPolygonalLine',data);
}
//饼图
export function getStatisticsByCircle(data) {
    return get('/api/transactions/getStatisticsByCircle',data);
}
//导出饼图
export function orderStatisticExport(data) {
    return getBlob('/api/transactions/orderStatisticExport',data);
}